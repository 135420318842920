import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="August 2020"
      subnav="release-notes">
      <div id="August2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          After the big bang of last month, we took a little break. It's a light
          update this month, but there are plenty of exciting things to come!
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.4.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.4.1"
            />

            <div className={styles.changesList}>
              <Fixed>
                The styling for{' '}
                <Link href="/components/buttons/button/design">
                  icon-only buttons
                </Link>
                .
              </Fixed>
              <Fixed>
                An issue for{' '}
                <Link href="/components/data-visualization/data-table/design">
                  data tables
                </Link>{' '}
                that have no sorting by default. (Thanks{' '}
                <Link href="https://github.com/jjstobbe">Jack!</Link>)
              </Fixed>
              <Fixed>
                A naming convention gaffe for our{' '}
                <Link href="/resources/code-variables/zindex-elevation/">
                  elevation variables
                </Link>
                . (Thanks <Link href="https://github.com/cz">Craig!</Link>)
              </Fixed>
              <Fixed>
                A dependency issue that may have caused issues with duplicate
                React versions. (Thanks{' '}
                <Link href="https://github.com/joshuabalfe">Josh</Link> and{' '}
                <Link href="https://github.com/kaymo">Kim!</Link>)
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                A new{' '}
                <Link href="/resources/design-assets/plugins">plugins</Link>{' '}
                page to showcase all of the plugins Hudl has built to make using
                Uniform and designing UI even easier (sorry, they're Hudl only).
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
